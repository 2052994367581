<template>
  <div class="homePage">
    <div class="title">
      <!-- {{ msg }} -->

      <div class="welcome">
        <TextScrambler :phrasesProp="welcomePhrases"></TextScrambler>
      </div>
    </div>

    <div class="flipButton">
      <CustomButton @click="this.$router.push('/projects')"></CustomButton>
    </div>

    <div v-if="displayInput" class="input">
      <TextScrambler
        :scrambleLoopLimitProp="1"
        :phrasesProp="['Login:']"
      ></TextScrambler>

      <input
        type="text"
        id="homeInput"
        :value="displayValue"
        @keyup="handleInput"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import CustomButton from "@/components/CustomButton";
import TextScrambler from "@/components/TextScrambler";

export default {
  name: "HelloWorld",
  components: {
    CustomButton,
    TextScrambler,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      displayInput: false,
      actualInput: "",
    };
  },
  computed: {
    welcomePhrases() {
      return [
        "Welcome", //English
        "Maligayang pagdating", //Filipino
        "Willkommen", //German
        "Witaj", //Polish
        "Welcome", //English
        "Bienvenido", //Spanish
        "欢迎", //Chinese (Mandarin)
        "स्वागत है", //Hindi
        "Welcome", //English
        "Bienvenue", //French
        "أهلاً وسهلاً", //Arabic
        "Добро пожаловать", //Russian
        "Welcome", //English
        "ようこそ", //Japanese
        "환영합니다", //Korean
        "Benvenuto", //Italian
      ];
    },
    displayValue() {
      // Mask the actual input
      return "*".repeat(this.actualInput.length);
    },
  },
  methods: {
    handleInput(event) {
      if (event.key === "Backspace" || event.key === "Delete") {
        this.actualInput = this.actualInput.slice(0, -1);
      } else if (event.key.length === 1) {
        this.actualInput += event.key;
      }
      this.checkPassword();
    },
    checkPassword() {
      if (this.actualInput === store.getters.getSecretValue) {
        const SECRET_PLACE = store.getters.getSecretPlace;
        this.$router.push({ path: `/${SECRET_PLACE}` });
      }
    },
    handleKeyPress(event) {
      console.log(event.key);
      if (event.key === "Enter") {
        this.displayInput = true;
        document.removeEventListener("keydown", this.handleKeyPress);
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style scoped lang="scss">
.homePage {
  display: flex;
  padding-top: 77px;
  flex-direction: column;
  align-items: center;

  // Makes container cover the entire page
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  //----------------------------------------------
}

.title {
  font-size: 3.5rem;
  font-family: serif;
  margin-bottom: 10%;
  text-shadow: 0 0 7px var(--color-07); // Replace with theme's accent color
  min-width: 300px;
  text-align: center;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 20px;
  font-family: sans-serif;
  text-shadow: 0 0 7px var(--color-07); // Replace with theme's accent color
}

.subText {
  margin-bottom: 5px;
}

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  font-size: 27px;

  .textScrambler {
    border: 1px solid var(--color-07); // Use theme's accent color
  }
}

.flipButton {
  display: flex;
  justify-content: center;
  width: 300px;

  button {
    font-size: 17px;
    font-weight: bold;
  }
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  max-height: 19px;
  margin: auto;

  .textScramble {
    margin-right: 3px;
    flex: 0 0 25%;
    max-width: 45px;
    max-height: 43px;
  }
}

input {
  flex: 0 0 75%;
  border: 0.1px solid var(--color-06); // Use theme's text color
  background: var(--color-01); // Use theme's background color
  color: var(--color-07); // Use theme's accent color
  font-weight: 900;
  letter-spacing: 2px;
}

@media (min-width: 768px) {
  .title {
    font-size: 6rem;
  }

  .welcome {
    font-size: 55px;
    min-height: 155px;
  }

  .text {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .welcome {
    font-size: 80px;
    min-height: 155px;
  }
}

@media (min-width: 992px) {
  .welcome {
    font-size: 100px;
    min-height: 165px;
  }
}
</style>
