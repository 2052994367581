<template>
  <div class="navBar" :class="{ navBarOpen: this.isMobileNavOpen }">
    <div class="nav">
      <div class="navContainer">
        <div
          class="navBarToggler"
          :style="{ backgroundImage: navbarTogglerSvg }"
          @click="toggleNavbarToggler()"
          @mouseover="setNavbarTogglerHover(true)"
          @mouseout="setNavbarTogglerHover(false)"
          :class="{
            navBarTogglerHovered: isNavBarTogglerHovered,
            navBarOpen: this.isMobileNavOpen,
          }"
        ></div>
        <div class="collapsibleBar">
          <button class="navButton" @click="navigateTo('/')">HOME</button>
          <button class="navButton" @click="navigateTo('/projects')">
            PROJECTS
          </button>
          <button
            v-if="isLoggedIn"
            class="navButton"
            @click="navigateTo('/projectsWIP')"
          >
            WIP
          </button>
          <button
            class="navButton"
            v-if="isLoggedIn"
            @click="navigateTo(placeToBe)"
          >
            {{ placeToBeText }}
          </button>
          <!-- Connect dropdown -->
          <div class="navDropdown">
            <button
              class="navButton navDropdownButton"
              :class="{
                connectDropdownHovered: isConnectDropdownHovered,
              }"
              @click="toggleConnectDropdown"
              @mouseover="setConnectDropdownHover(true)"
              @mouseout="setConnectDropdownHover(false)"
            >
              CONNECT
            </button>
            <!-- Dropdown menu -->
            <div class="navDropdownContent" v-show="isConnectDropdownOpen">
              <a
                href="//github.com/chrissc6"
                target="_blank"
                class="navDropdownItem"
                @click="closeTabBar"
                >GITHUB</a
              >
              <a
                href="//codepen.io/chrissc6"
                target="_blank"
                class="navDropdownItem"
                @click="closeTabBar"
                >CODEPEN</a
              >
              <a
                href="//linkedin.com/in/christopher-caudill"
                target="_blank"
                class="navDropdownItem"
                @click="closeTabBar"
                >LINKEDIN</a
              >
            </div>
          </div>
          <button class="navButton" v-if="isLoggedIn" @click="logout">
            Logout
          </button>
          <!-- Theme dropdown -->
          <div class="navDropdown right">
            <button
              class="navButton navDropdownButton"
              :class="{
                themeDropdownHovered: isThemeDropdownHovered,
              }"
              @click="toggleThemeDropdown"
              @mouseover="setThemeDropdownHover(true)"
              @mouseout="setThemeDropdownHover(false)"
            >
              THEME
            </button>
            <!-- Dropdown menu -->
            <div
              class="navDropdownContent subRight"
              v-show="isThemeDropdownOpen"
            >
              <button class="navDropdownItem" @click="updateTheme('dark')">
                Dark
              </button>
              <button class="navDropdownItem" @click="updateTheme('light')">
                Light
              </button>
              <button class="navDropdownItem" @click="updateTheme('earth')">
                Earth
              </button>
              <button class="navDropdownItem" @click="updateTheme('wind')">
                Wind
              </button>
              <button class="navDropdownItem" @click="updateTheme('fire')">
                Fire
              </button>
              <button class="navDropdownItem" @click="updateTheme('water')">
                Water
              </button>
              <!-- <button class="navDropdownItem" @click="updateTheme('christmas')">
                Christmas
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <router-view id="routerView" />
  </div>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      busyCursor: require("@/assets/cursors/TD_busy.gif"),
      workingCursor: require("@/assets/cursors/TD_working.gif"),
      isMobileNavOpen: false,
      isConnectDropdownOpen: false,
      isThemeDropdownOpen: false,
      isNavBarTogglerHovered: false,
      isConnectDropdownHovered: false,
      isThemeDropdownHovered: false,
    };
  },
  mounted() {
    document.documentElement.setAttribute(
      "data-theme",
      this.$store.getters.currentTheme
    );

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    checkTouchDevice() {
      const maxTouchPoints = navigator.maxTouchPoints || 0;
      const hoverCapability = window.matchMedia("(hover: hover)").matches;

      const settingsMatchTouchDevice = maxTouchPoints > 0 && !hoverCapability;

      store.commit("SET_IS_TOUCH_DEVICE", settingsMatchTouchDevice);

      // console.log(
      //   "Touch device check - isTouchDevice:",
      //   settingsMatchTouchDevice
      // );
    },
    handleResize() {
      this.checkTouchDevice();

      if (window.innerWidth > 992) {
        this.closeTabBar();
      }
    },
    setNavbarTogglerHover(value) {
      if (!this.isTouchDevice) {
        this.isNavBarTogglerHovered = value;
      }
    },
    setConnectDropdownHover(value) {
      if (!this.isTouchDevice) {
        this.isConnectDropdownHovered = value;
      }
    },
    setThemeDropdownHover(value) {
      if (!this.isTouchDevice) {
        this.isThemeDropdownHovered = value;
      }
    },
    handleClickOutside(event) {
      // Check if click is outside of the dropdown and if the dropdown is open
      if (this.isConnectDropdownOpen && !this.$el.contains(event.target)) {
        this.isConnectDropdownOpen = false;
      }
      if (this.isThemeDropdownOpen && !this.$el.contains(event.target)) {
        this.isThemeDropdownOpen = false;
      }
    },
    toggleConnectDropdown() {
      event.stopPropagation();
      this.isConnectDropdownOpen = !this.isConnectDropdownOpen;
      this.isThemeDropdownOpen = false;
    },
    toggleThemeDropdown() {
      event.stopPropagation();
      this.isThemeDropdownOpen = !this.isThemeDropdownOpen;
      this.isConnectDropdownOpen = false;
    },
    setBusyCursor(isBusy) {
      if (isBusy) {
        document.body.classList.add("busy");
      } else {
        document.body.classList.remove("busy");
      }
    },
    toggleNavbarToggler() {
      this.isMobileNavOpen = !this.isMobileNavOpen;
      this.isThemeDropdownOpen = false;
      this.isConnectDropdownOpen = false;
    },
    closeTabBar() {
      this.isMobileNavOpen = false;
      this.isThemeDropdownOpen = false;
      this.isConnectDropdownOpen = false;
    },
    updateTheme(newTheme) {
      document.documentElement.setAttribute("data-theme", newTheme);
      store.commit("SET_THEME", newTheme);
      this.closeTabBar();
    },
    logout() {
      localStorage.removeItem("chriscaudill-username");
      localStorage.removeItem("chriscaudill-token");
      store.commit("SET_LOGGED_IN", false);
      this.closeTabBar();
    },
    navigateTo(route) {
      this.$router.push(route);
      this.closeTabBar();
    },
  },
  computed: {
    currentTheme() {
      return this.$store.getters.currentTheme;
    },
    isTouchDevice() {
      return this.$store.getters.isTouchDevice;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    placeToBe() {
      return store.getters.getPlaceToBe;
    },
    placeToBeText() {
      return store.getters.getPlaceToBeText;
    },
    navbarTogglerSvg() {
      const svgBase =
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="SVG_STROKE_COLOR" stroke-linecap="round" stroke-miterlimit="10" stroke-width="SVG_STROKE_WIDTH" d="SVG_PATH_DATA"/></svg>';

      let hamburgerIconStrokeColor = "rgb(255,106,19)";
      let hamburgerIconHoverStrokeColor = "rgb(28,172,122)";
      let xIconStrokeColor = "rgb(255,106,19)";
      let xIconHoverStrokeColor = "rgb(28,172,122)";

      switch (this.currentTheme) {
        case "dark":
          hamburgerIconStrokeColor = "rgb(255,106,19)";
          hamburgerIconHoverStrokeColor = "rgb(28,172,122)";
          xIconStrokeColor = "rgb(255,106,19)";
          xIconHoverStrokeColor = "rgb(28,172,122)";
          break;
        case "light":
          hamburgerIconStrokeColor = "rgb(102,230,255)";
          hamburgerIconHoverStrokeColor = "rgb(0,0,255)";
          xIconStrokeColor = "rgb(0,85,102)";
          xIconHoverStrokeColor = "rgb(0,0,255)";
          break;
        case "christmas":
          hamburgerIconStrokeColor = "rgb(77,255,77)";
          hamburgerIconHoverStrokeColor = "rgb(204,0,0)";
          xIconStrokeColor = "rgb(255,25,25)";
          xIconHoverStrokeColor = "rgb(34,204,0)";
          break;
        default:
          hamburgerIconStrokeColor = "rgb(255,106,19)";
          hamburgerIconHoverStrokeColor = "rgb(28,172,122)";
          xIconStrokeColor = "rgb(255,106,19)";
          xIconHoverStrokeColor = "rgb(28,172,122)";
      }

      const hamburgerIconStrokeWidth = "2";
      const hamburgerIconPathData = "M4 7h22M4 15h22M4 23h22";
      const xIconStrokeWidth = "2.5";
      const xIconPathData = "M6 6l18 18M6 24L24 6";

      let strokeColor = hamburgerIconStrokeColor;
      let strokeWidth = hamburgerIconStrokeWidth;
      let pathData = hamburgerIconPathData;

      if (this.isNavBarTogglerHovered) {
        strokeColor = hamburgerIconHoverStrokeColor;
      }

      if (this.isMobileNavOpen) {
        strokeWidth = xIconStrokeWidth;
        pathData = xIconPathData;
        strokeColor = xIconStrokeColor;

        if (this.isNavBarTogglerHovered) {
          strokeColor = xIconHoverStrokeColor;
        }
      }

      let svgUpdatedValue = svgBase
        .replace("SVG_STROKE_COLOR", strokeColor)
        .replace("SVG_STROKE_WIDTH", strokeWidth)
        .replace("SVG_PATH_DATA", pathData);

      const encodedSvg = encodeURIComponent(svgUpdatedValue)
        .replace(/'/g, "%27")
        .replace(/"/g, "%22");

      return `url('data:image/svg+xml,${encodedSvg}')`;
    },
  },
};
</script>

<style lang="scss">
//Theme colors
// var(--color-0);
:root {
  /* Dark theme */
  --color-01: #1f1f1f;
  --color-02: #252525;
  --color-03: #232726;
  --color-04: #3c3b3b;
  --color-05: #171a19;
  --color-06: white;
  --color-07: #1cac7a;
  --color-08: #16513c;
  --color-09: #ff7f00;

  /* CustomButton-specific colors */
  --custom-button-color-01: #000000; /* Text color */
  --custom-button-color-02: rgb(0, 86, 49); /* Gradient start */
  --custom-button-color-03: #ff955b; /* Gradient end */
  --custom-button-color-04: rgb(255, 255, 255); /* White for shadow */
  --custom-button-color-05: rgb(0, 255, 69); /* Bright green for shadow */
  --custom-button-color-06: rgb(255, 155, 0); /* Orange for shadow */
  --custom-button-color-07: #fafafa; /* Light gray for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #2acc5f;
  --project-info-hover-bg: #042710;
  --project-info-text-shadow: #5ca77f;
  --project-info-gradient-start: #1f1f1f;
  --project-info-gradient-end: #042710;
  /* Timer-specific colors */
  --timer-bg-color: #1f1f1f; /* Background color for TimerContainer */
  --timer-text-color: white; /* Default text color */
  --timer-header-color: #1cac7a; /* Header text color */
  --timer-label-color: #ffffff; /* Labels text color */
  --timer-input-bg: #252525; /* Input background color */
  --timer-input-text-color: #07fff6; /* Input text color */
  --timer-button-border: #fff0e2; /* Button border color */
  --timer-button-bg: #135211; /* Button background color */
  --timer-button-text-color: #ffffff; /* Button text color */
  --timer-button-hover-bg: #000000; /* Button hover background */
  --timer-button-hover-text: #9ce299; /* Button hover text color */
  --timer-button-active-bg: #000000; /* Button active background */
  --timer-button-active-text: #135211; /* Button active text color */
  --timer-disabled-border: #555555; /* Disabled button border */
  --timer-disabled-bg: #929292; /* Disabled button background */
  --timer-disabled-text: #ffffff; /* Disabled button text */
  --timer-shadow-color: #5ca77f; /* Timer text shadow */
}

[data-theme="light"] {
  --color-01: #f5f5f5; /* Light equivalent of #1f1f1f */
  --color-02: #e8e8e8; /* Light equivalent of #252525 */
  --color-03: #f0f0f0; /* Light equivalent of #232726 */
  --color-04: #d4d4d4; /* Light equivalent of #3c3b3b */
  --color-05: #fafafa; /* Light equivalent of #171a19 */
  --color-06: #1f1f1f; /* Dark equivalent of white */
  --color-07: #1cac7a; /* Accent color remains the same */
  --color-08: #16513c; /* Accent color remains the same */
  --color-09: #ff7f00; /* Accent color remains the same */

  /* CustomButton-specific colors */
  --custom-button-color-01: #1f1f1f; /* Text color */
  --custom-button-color-02: #1cac7a; /* Gradient start */
  --custom-button-color-03: #ff7f00; /* Gradient end */
  --custom-button-color-04: #ffffff; /* White for shadow */
  --custom-button-color-05: #00cc44; /* Bright green for shadow */
  --custom-button-color-06: #ffa500; /* Orange for shadow */
  --custom-button-color-07: #f0f0f0; /* Light gray for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #4caf50;
  --project-info-hover-bg: #e8f5e9;
  --project-info-text-shadow: #388e3c;
  --project-info-gradient-start: #fafafa;
  --project-info-gradient-end: #c8e6c9;
  /* Timer-specific colors */
  --timer-bg-color: #ffffff;
  --timer-text-color: #000000;
  --timer-header-color: #4caf50;
  --timer-label-color: #333333;
  --timer-input-bg: #f5f5f5;
  --timer-input-text-color: #4caf50;
  --timer-button-border: #4caf50;
  --timer-button-bg: #8bc34a;
  --timer-button-text-color: white;
  --timer-button-hover-bg: #4caf50;
  --timer-button-hover-text: #ffffff;
  --timer-button-active-bg: #388e3c;
  --timer-button-active-text: #ffffff;
  --timer-disabled-border: #aaaaaa;
  --timer-disabled-bg: #e0e0e0;
  --timer-disabled-text: #777777;
  --timer-shadow-color: #388e3c;
}

[data-theme="earth"] {
  --color-01: #f5f5dc; /* Beige */
  --color-02: #e0e0d1; /* Light Taupe */
  --color-03: #c8c8b5; /* Soft Taupe */
  --color-04: #a6a57a; /* Olive Green */
  --color-05: #8f8b5b; /* Muted Olive */
  --color-06: #5a5a3d; /* Dark Olive */
  --color-07: #4b5320; /* Army Green */
  --color-08: #6b8e23; /* Olive Drab */
  --color-09: #556b2f; /* Dark Olive Green */

  /* CustomButton-specific colors */
  --custom-button-color-01: #5a5a3d; /* Text color */
  --custom-button-color-02: #4b5320; /* Gradient start */
  --custom-button-color-03: #a6a57a; /* Gradient end */
  --custom-button-color-04: #f5f5dc; /* Beige for shadow */
  --custom-button-color-05: #6b8e23; /* Bright green for shadow */
  --custom-button-color-06: #8f8b5b; /* Olive for shadow */
  --custom-button-color-07: #e0e0d1; /* Light taupe for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #6b8e23;
  --project-info-hover-bg: #556b2f;
  --project-info-text-shadow: #4b5320;
  --project-info-gradient-start: #8f8b5b;
  --project-info-gradient-end: #556b2f;
  /* Timer-specific colors */
  --timer-bg-color: #f5f5dc; /* Beige */
  --timer-text-color: #4b5320; /* Army Green */
  --timer-header-color: #6b8e23; /* Olive Drab */
  --timer-label-color: #5a5a3d; /* Dark Olive */
  --timer-input-bg: #e0e0d1; /* Light Taupe */
  --timer-input-text-color: #4b5320; /* Army Green */
  --timer-button-border: #6b8e23; /* Olive Drab */
  --timer-button-bg: #8f8b5b; /* Muted Olive */
  --timer-button-text-color: #ffffff; /* White */
  --timer-button-hover-bg: #6b8e23; /* Olive Drab */
  --timer-button-hover-text: #f5f5dc; /* Beige */
  --timer-button-active-bg: #4b5320; /* Army Green */
  --timer-button-active-text: #e0e0d1; /* Light Taupe */
  --timer-disabled-border: #8c8c8c; /* Grayish Olive */
  --timer-disabled-bg: #c8c8b5; /* Soft Taupe */
  --timer-disabled-text: #5a5a3d; /* Dark Olive */
  --timer-shadow-color: #4b5320; /* Army Green */
}

[data-theme="wind"] {
  --color-01: #f0f8ff; /* Alice Blue */
  --color-02: #e6f0fa; /* Light Sky Blue */
  --color-03: #d4e6f1; /* Light Blue */
  --color-04: #a9d0f5; /* Soft Blue */
  --color-05: #b0c4de; /* Light Steel Blue */
  --color-06: #4682b4; /* Steel Blue */
  --color-07: #5f9ea0; /* Cadet Blue */
  --color-08: #6495ed; /* Cornflower Blue */
  --color-09: #1e90ff; /* Dodger Blue */

  /* CustomButton-specific colors */
  --custom-button-color-01: #4682b4; /* Text color */
  --custom-button-color-02: #5f9ea0; /* Gradient start */
  --custom-button-color-03: #1e90ff; /* Gradient end */
  --custom-button-color-04: #f0f8ff; /* Alice Blue for shadow */
  --custom-button-color-05: #6495ed; /* Bright blue for shadow */
  --custom-button-color-06: #a9d0f5; /* Light blue for shadow */
  --custom-button-color-07: #d4e6f1; /* Soft blue for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #6495ed;
  --project-info-hover-bg: #e6f0fa;
  --project-info-text-shadow: #5f9ea0;
  --project-info-gradient-start: #b0c4de;
  --project-info-gradient-end: #1e90ff;
  /* Timer-specific colors */
  --timer-bg-color: #f0f8ff; /* Alice Blue */
  --timer-text-color: #4682b4; /* Steel Blue */
  --timer-header-color: #5f9ea0; /* Cadet Blue */
  --timer-label-color: #6495ed; /* Cornflower Blue */
  --timer-input-bg: #e6f0fa; /* Light Sky Blue */
  --timer-input-text-color: #4682b4; /* Steel Blue */
  --timer-button-border: #5f9ea0; /* Cadet Blue */
  --timer-button-bg: #b0c4de; /* Light Steel Blue */
  --timer-button-text-color: #ffffff; /* White */
  --timer-button-hover-bg: #6495ed; /* Cornflower Blue */
  --timer-button-hover-text: #e6f0fa; /* Light Sky Blue */
  --timer-button-active-bg: #5f9ea0; /* Cadet Blue */
  --timer-button-active-text: #f0f8ff; /* Alice Blue */
  --timer-disabled-border: #a9d0f5; /* Soft Blue */
  --timer-disabled-bg: #d4e6f1; /* Light Blue */
  --timer-disabled-text: #b0c4de; /* Light Steel Blue */
  --timer-shadow-color: #5f9ea0; /* Cadet Blue */
}

[data-theme="fire"] {
  --color-01: #ffe5e0; /* Light Salmon */
  --color-02: #ffcccb; /* Pastel Pink */
  --color-03: #ff9999; /* Light Coral */
  --color-04: #ff6666; /* Light Red */
  --color-05: #ff3333; /* Bright Red */
  --color-06: #cc0000; /* Dark Red */
  --color-07: #990000; /* Darker Red */
  --color-08: #660000; /* Deep Red */
  --color-09: #330000; /* Very Dark Red */

  /* CustomButton-specific colors */
  --custom-button-color-01: #ffffff; /* Text color for better contrast */
  --custom-button-color-02: #cc0000; /* Gradient start */
  --custom-button-color-03: #ff3333; /* Gradient end */
  --custom-button-color-04: #ffe5e0; /* Light salmon for shadow */
  --custom-button-color-05: #ff6666; /* Bright red for shadow */
  --custom-button-color-06: #ffcccb; /* Pastel pink for shadow */
  --custom-button-color-07: #ff9999; /* Light coral for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #ff4500;
  --project-info-hover-bg: #990000;
  --project-info-text-shadow: #ff6347;
  --project-info-gradient-start: #ff9999;
  --project-info-gradient-end: #cc0000;
  /* Timer-specific colors */
  --timer-bg-color: #ffe5e0; /* Light Salmon */
  --timer-text-color: #cc0000; /* Dark Red */
  --timer-header-color: #ff4500; /* Orange Red */
  --timer-label-color: #990000; /* Darker Red */
  --timer-input-bg: #ffcccb; /* Pastel Pink */
  --timer-input-text-color: #cc0000; /* Dark Red */
  --timer-button-border: #ff4500; /* Orange Red */
  --timer-button-bg: #ff9999; /* Light Coral */
  --timer-button-text-color: #ffffff; /* White */
  --timer-button-hover-bg: #ff6666; /* Light Red */
  --timer-button-hover-text: #ffe5e0; /* Light Salmon */
  --timer-button-active-bg: #cc0000; /* Dark Red */
  --timer-button-active-text: #ffcccb; /* Pastel Pink */
  --timer-disabled-border: #ff9999; /* Light Coral */
  --timer-disabled-bg: #ffcccb; /* Pastel Pink */
  --timer-disabled-text: #990000; /* Darker Red */
  --timer-shadow-color: #ff6347; /* Tomato Red */
}

[data-theme="water"] {
  --color-01: #e0f7fa; /* Light Cyan */
  --color-02: #b2ebf2; /* Pale Cyan */
  --color-03: #80deea; /* Soft Cyan */
  --color-04: #4dd0e1; /* Cyan */
  --color-05: #26c6da; /* Bright Cyan */
  --color-06: #00796b; /* Teal */
  --color-07: #004d40; /* Dark Teal */
  --color-08: #00796b; /* Dark Teal */
  --color-09: #00838f; /* Dark Cyan */

  /* CustomButton-specific colors */
  --custom-button-color-01: #ffffff; /* Text color for better contrast */
  --custom-button-color-02: #004d40; /* Gradient start */
  --custom-button-color-03: #26c6da; /* Gradient end */
  --custom-button-color-04: #e0f7fa; /* Light cyan for shadow */
  --custom-button-color-05: #80deea; /* Soft cyan for shadow */
  --custom-button-color-06: #4dd0e1; /* Cyan for shadow */
  --custom-button-color-07: #b2ebf2; /* Pale cyan for border */
  /* ProjectInfo-specific colors */
  --project-info-border-color: #00796b;
  --project-info-hover-bg: #004d40;
  --project-info-text-shadow: #26c6da;
  --project-info-gradient-start: #b2ebf2;
  --project-info-gradient-end: #00796b;
  /* Timer-specific colors */
  --timer-bg-color: #e0f7fa; /* Light Cyan */
  --timer-text-color: #004d40; /* Dark Teal */
  --timer-header-color: #26c6da; /* Bright Cyan */
  --timer-label-color: #00796b; /* Teal */
  --timer-input-bg: #b2ebf2; /* Pale Cyan */
  --timer-input-text-color: #004d40; /* Dark Teal */
  --timer-button-border: #26c6da; /* Bright Cyan */
  --timer-button-bg: #80deea; /* Soft Cyan */
  --timer-button-text-color: #ffffff; /* White */
  --timer-button-hover-bg: #4dd0e1; /* Cyan */
  --timer-button-hover-text: #e0f7fa; /* Light Cyan */
  --timer-button-active-bg: #00796b; /* Teal */
  --timer-button-active-text: #b2ebf2; /* Pale Cyan */
  --timer-disabled-border: #80deea; /* Soft Cyan */
  --timer-disabled-bg: #b2ebf2; /* Pale Cyan */
  --timer-disabled-text: #004d40; /* Dark Teal */
  --timer-shadow-color: #26c6da; /* Bright Cyan */
}

[data-theme="christmas"] {
  --color-01: #1f1f1f;
  --color-02: #252525;
  --color-03: #232726;
  --color-04: #3c3b3b;
  --color-05: #171a19;
  --color-06: white;
  --color-07: #1cac7a;
  --color-08: #16513c;
  --color-09: #c42121;
}

//Cursors
html {
  cursor: url("./assets/cursors/TD_arrow.cur"), auto; //Applying the default cursor
}
a {
  cursor: url("./assets/cursors/TD_link.cur"), pointer; //Cursor for links
}
button {
  cursor: url("./assets/cursors/TD_link.cur"), pointer;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: url("./assets/cursors/TD_link.cur"), pointer;
}

//Fonts
//font converter - https://transfonter.org/
@font-face {
  font-family: "Digital-7";
  src: url(@/assets/fonts/Digital-7.eot);
  src: url(@/assets/fonts/Digital-7.eot?#iefix) format("embedded-opentype"),
    url(@/assets/fonts/Digital-7.woff2) format("woff2"),
    url(@/assets/fonts/Digital-7.woff) format("woff"),
    url(@/assets/fonts/Digital-7.ttf) format("truetype"),
    url(@/assets/fonts/Digital-7.svg#Digital-7) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JMLetter";
  src: url(@/assets/fonts/JMLetter.eot);
  src: url(@/assets/fonts/JMLetter.eot?#iefix) format("embedded-opentype"),
    url(@/assets/fonts/JMLetter.woff2) format("woff2"),
    url(@/assets/fonts/JMLetter.woff) format("woff"),
    url(@/assets/fonts/JMLetter.ttf) format("truetype"),
    url(@/assets/fonts/JMLetter.svg#JMLetter) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SpaceMono-Regular";
  src: url(@/assets/fonts/SpaceMono-Regular.eot);
  src: url(@/assets/fonts/SpaceMono-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(@/assets/fonts/SpaceMono-Regular.woff2) format("woff2"),
    url(@/assets/fonts/SpaceMono-Regular.woff) format("woff"),
    url(@/assets/fonts/SpaceMono-Regular.ttf) format("truetype"),
    url(@/assets/fonts/SpaceMono-Regular.svg#SpaceMono-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SpaceMono-Bold";
  src: url(@/assets/fonts/SpaceMono-Bold.eot);
  src: url(@/assets/fonts/SpaceMono-Bold.eot?#iefix) format("embedded-opentype"),
    url(@/assets/fonts/SpaceMono-Bold.woff2) format("woff2"),
    url(@/assets/fonts/SpaceMono-Bold.woff) format("woff"),
    url(@/assets/fonts/SpaceMono-Bold.ttf) format("truetype"),
    url(@/assets/fonts/SpaceMono-Bold.svg#SpaceMono-Bold) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "OpenDyslexic-Regular";
  src: url(@/assets/fonts/OpenDyslexic-Regular.eot);
  src: url(@/assets/fonts/OpenDyslexic-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(@/assets/fonts/OpenDyslexic-Regular.woff2) format("woff2"),
    url(@/assets/fonts/OpenDyslexic-Regular.woff) format("woff"),
    url(@/assets/fonts/OpenDyslexic-Regular.ttf) format("truetype"),
    url(@/assets/fonts/OpenDyslexic-Regular.svg#OpenDyslexic-Regular)
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//Note that &#9776; is an HTML entity that displays the ☰ (hamburger icon.)

html {
  background-color: var(--color-01) !important;
  user-select: none;
}

body {
  background-color: var(--color-01) !important;
}

.navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 99.94%;
  z-index: 100;
  box-sizing: border-box;
  border: 1px solid var(--color-06);

  border-radius: 1%;
  animation: borderRadiusAnimation 25s ease-in-out infinite;
}

@keyframes borderRadiusAnimation {
  0%,
  100% {
    border-radius: 4%;
  }
  50% {
    border-radius: 7%;
  }
}

.nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--color-02);
  width: 100%;
}

.navContainer {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: var(0.75rem);
  padding-left: var(0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.navBarToggler {
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
  cursor: url("./assets/cursors/TD_link.cur"), pointer;

  // background-image: url("data:image/svg+xml,<svg xmlns='//www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgb(255,106,19)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

  width: 3rem;
  height: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  background-color: var(--color-04);
  border: 1px solid var(--color-07);
  color: var(--color-09);

  transition: background-color 0.75s ease-in-out, border-color 0.25s ease-in-out,
    color 0.5s ease-in-out, background-image 0.1s ease-in-out;
}

.navBarToggler.navBarTogglerHovered {
  background-color: var(--color-05);
  border: 1px solid var(--color-09);
  color: var(--color-07);
  // background-image: url("data:image/svg+xml,<svg xmlns='//www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgb(28,172,122)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navBarToggler.navBarOpen {
  background-color: var(--color-04) !important;
  border: 1px solid var(--color-07);
  color: var(--color-09);
  // background-image: url("data:image/svg+xml,<svg xmlns='//www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgb(255,106,19)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' d='M6 6l18 18M6 24L24 6'/></svg>");
}

.navBarToggler.navBarTogglerHovered.navBarOpen {
  background-color: var(--color-05) !important;
  border: 1px solid var(--color-09);
  color: var(--color-07);
  // background-image: url("data:image/svg+xml,<svg xmlns='//www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgb(28,172,122)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' d='M6 6l18 18M6 24L24 6'/></svg>");
}

.collapsibleBar {
  display: none;
  width: 100%;
}

.navButton {
  background-color: var(--color-05);
  border: 1px solid var(--color-07);
  color: var(--color-09);
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: url("./assets/cursors/TD_link.cur"), pointer;
  transition: background-color 0.3s ease;
  font-family: "SpaceMono-Bold";

  &:hover {
    background-color: var(--color-04);
    border: 1px solid var(--color-09);
    color: var(--color-07);
  }
}

.navDropdownContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border: 2px solid var(--color-09);
  position: relative;
  margin-left: 2rem;
}

.navDropdownContent::before,
.navDropdownContent::after {
  content: "";
  position: absolute;
}

.navDropdownContent::before {
  border: 4px solid var(--color-08);
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
}

.navDropdownContent .navDropdownItem {
  background-color: var(--color-05);
  border: 1px solid var(--color-09);
  color: var(--color-07);
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  font-size: 1rem;
  cursor: url("./assets/cursors/TD_link.cur"), pointer;
  transition: background-color 0.3s ease;
  font-family: "SpaceMono-Bold";
  text-decoration: none;
  display: block;
  z-index: 1;
}

.navButton.navDropdownButton {
  &:hover {
    background-color: var(--color-05);
    border: 1px solid var(--color-07);
    color: var(--color-09);
  }
}

.navButton.navDropdownButton.connectDropdownHovered {
  background-color: var(--color-04);
  border: 1px solid var(--color-09);
  color: var(--color-07);
}

.navButton.navDropdownButton.themeDropdownHovered {
  background-color: var(--color-04);
  border: 1px solid var(--color-09);
  color: var(--color-07);
}

.navDropdownContent .navDropdownItem:hover {
  background-color: var(--color-04);
  border: 1px solid var(--color-09);
  color: var(--color-07);
}

#routerView {
  padding-top: 77px;
  color: var(--color-09);
}

.navBarOpen {
  background-color: var(--color-08) !important;
  border: unset;

  .nav {
    background-color: var(--color-08) !important;
  }

  .navContainer {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navBarToggler {
    margin-bottom: 0.5rem;
  }

  .collapsibleBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    margin-left: 0.75rem;
    border-top: 1px solid var(--color-06);
    padding-top: 1rem;
    border-start-start-radius: 7px;
  }

  .navButton {
    margin: 0.3rem;
  }

  .right {
    margin-left: unset;
  }
}

@media (min-width: 992px) {
  .navBarToggler {
    display: none;
  }

  .collapsibleBar {
    display: flex !important;
    flex-basis: auto;
  }

  .navDropdownContent {
    position: absolute;
    margin-top: 1.25rem;
    margin-bottom: unset;
    background-color: var(--color-03);
  }

  .navDropdownContent::before {
    border: 4px solid var(--color-01);
  }

  .navDropdownContent.subRight::before {
    border-bottom-right-radius: unset;
    border-top-left-radius: unset;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .right {
    margin-left: auto;
  }

  .subRight {
    align-items: flex-start;
    margin-left: -4rem;
  }
}
</style>
