<template>
  <div class="customButton">
    <span class="hover">Explore</span>
    <span class="default">View Projects</span>
  </div>
</template>

<script>
export default {
  name: "CustomButton",
};
</script>

<style scoped>
.customButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: url("../assets/cursors/TD_link.cur"), pointer;
  width: 130px;
  height: 40px;
  color: var(--custom-button-color-01); /* Text color */
  border-radius: 5px;
  font-family: "SpaceMono-Bold";
  font-weight: 500;
  background: transparent;
  transition: all 0.3s ease;
  outline: none;
  line-height: 42px;
  perspective: 230px;
}

.customButton span {
  display: flex;
  position: absolute;
  width: 100%; /* Adjusted to fill the parent */
  height: 100%; /* Adjusted to fill the parent */
  border-radius: 3px;
  margin: 0;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.4s;
  transform-origin: 50% 50% -20px;
  background: linear-gradient(
    0deg,
    var(--custom-button-color-02) 0%,
    var(--custom-button-color-03) 100%
  );
}

.customButton span.hover {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  background: linear-gradient(
    0deg,
    var(--custom-button-color-03) 0%,
    var(--custom-button-color-02) 100%
  );
  transform: rotateX(90deg);
}

.customButton:hover span.hover {
  margin-top: unset;
  box-shadow: inset 1px 1px 4px 0px var(--custom-button-color-04),
    -2px -2px 9px 1px var(--custom-button-color-05),
    2px 2px 9px 1px var(--custom-button-color-06);
  transform: rotateX(0deg);
}

.customButton:hover span.default {
  margin-top: -1px;
  border: 1px solid var(--custom-button-color-07);
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  color: transparent;
  transform: rotateX(-90deg);
}

.customButton span.default {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateX(0deg);
}

@media (min-width: 768px) {
  .customButton {
    width: 260px;
    height: 80px;
    font-size: 30px;
  }

  .customButton span {
    transform-origin: 50% 50% -40px;
  }
}
</style>
