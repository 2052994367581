<template>
  <div v-if="showScramblerProp" class="textScramble">
    <div class="textScramblerContainer">
      <div class="textScrambler"></div>
      <!-- Text scrambler -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TextScrambler",
  props: {
    showScramblerProp: {
      type: Boolean,
      default: true,
    },
    phrasesProp: {
      type: Array,
      default: () => ["Testing", "123"],
    },
    scrambleCharsProp: {
      type: String,
      default: () =>
        `qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM!@#$%^&*()-=+,./<>?;':"[]{}\\|`,
    },
    scrambleDelayProp: {
      type: Number,
      default: 800,
    },
    scrambleLoopLimitProp: {
      type: [Number, String], // Allowing both Number and specific String values
      default: "unlimited",
    },
  },
  methods: {
    startScrambleEffect() {
      const phrases = this.phrasesProp;
      const textElement = this.$el.querySelector(".textScrambler");
      const textEffect = new this.TextScrambler(
        textElement,
        this.scrambleCharsProp
      );

      let currentPhraseIndex = 0;
      let completedCycles = 0; // Tracks the number of times all phrases have been shown
      const totalPhrases = phrases.length;
      const loopLimit = this.scrambleLoopLimitProp; // Can be a number or 'unlimited'

      const displayNextPhrase = () => {
        // Check if loop limit is reached (considering all phrases shown once as one loop)
        if (
          loopLimit !== "unlimited" &&
          completedCycles >= loopLimit * totalPhrases
        ) {
          return; // Stop if the maximum number of cycles has been reached
        }

        textEffect.setText(phrases[currentPhraseIndex]).then(() => {
          let nextIndex = (currentPhraseIndex + 1) % totalPhrases;
          // Increment completedCycles when returning to the first phrase
          if (nextIndex === 0) {
            completedCycles += totalPhrases;
          }
          setTimeout(displayNextPhrase, this.scrambleDelayProp);
          currentPhraseIndex = nextIndex;
        });
      };

      displayNextPhrase();
    },
    TextScrambler: class {
      constructor(element, scrambleChars) {
        this.element = element;
        this.scrambleChars = scrambleChars;
        this.update = this.update.bind(this);
      }
      setText(newText) {
        const oldText = this.element.innerText;
        const targetLength = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.transitionQueue = [];
        for (let i = 0; i < targetLength; i++) {
          const fromChar = oldText[i] || "";
          const toChar = newText[i] || "";
          const startFrame = Math.floor(Math.random() * 40);
          const endFrame = startFrame + Math.floor(Math.random() * 40);
          this.transitionQueue.push({ fromChar, toChar, startFrame, endFrame });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
      }
      update() {
        let output = "";
        let completedTransitions = 0;
        this.transitionQueue.forEach((transition, index) => {
          let { fromChar, toChar, startFrame, endFrame, char } = transition;
          if (this.frame >= endFrame) {
            completedTransitions++;
            output += toChar;
          } else if (this.frame >= startFrame) {
            if (!char || Math.random() < 0.28) {
              char = this.randomChar();
              this.transitionQueue[index].char = char;
            }
            output += `<span class="ranChar">${char}</span>`;
          } else {
            output += fromChar;
          }
        });
        this.element.innerHTML = output;
        if (completedTransitions === this.transitionQueue.length) {
          this.resolve();
        } else {
          this.frameRequest = requestAnimationFrame(this.update);
          this.frame++;
        }
      }
      randomChar() {
        return this.scrambleChars[
          Math.floor(Math.random() * this.scrambleChars.length)
        ];
      }
    },
  },
  mounted() {
    this.startScrambleEffect();
  },
};
</script>

<style scoped>
.randomText {
  color: #757575;
}
</style>
