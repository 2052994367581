//Global variables
import { createStore } from 'vuex'

export default createStore({
  state: {
    theme: "dark",
    isLoggedIn: false,
    // apiUrl: "http://localhost:5278/",
    apiUrl: "https://testdbapp-webapp.azurewebsites.net/",
    homePageValue: "login",
    headerLink: "MemberProjects",
    headerLinkText: "Member Projects",
    isTouchDevice: false,
  },
  getters: {
    currentTheme: state => state.theme,
    isLoggedIn: state => state.isLoggedIn,
    getApiUrl: state => state.apiUrl,
    getSecretValue: state => state.homePageValue,
    getSecretPlace: state => state.homePageValue,
    getPlaceToBe: state => state.headerLink,
    getPlaceToBeText: state => state.headerLinkText,
    isTouchDevice: state => state.isTouchDevice,
  },
  mutations: {
    SET_THEME: (state, value) => {
      state.theme = value;
      localStorage.setItem('theme', value);
    },
    SET_LOGGED_IN: (state, value) => {
      state.isLoggedIn = value;
    },
    SET_IS_TOUCH_DEVICE: (state, value) => {
      state.isTouchDevice = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
